

import {Component, Vue} from "vue-property-decorator";
import {DataTableHeader} from 'vuetify';
import {namespace} from "vuex-class";

import {CentralSystem} from "@/domain";
import CrudDataTable from "@/components/CrudDataTable.vue";
import Config from "@/domain/config";


const centralSystemsModule = namespace('centralSystems');

@Component({
  components: {CrudDataTable},
  data: () => ({
    config: {
      defaultPageSize: 15,
    } as Partial<Config>,
  })
})
export default class CentralSystems extends Vue {

  @centralSystemsModule.State('loading')
  readonly loading!: boolean;

  @centralSystemsModule.State('items')
  readonly centralSystems!: CentralSystem[];

  headers: DataTableHeader[] = [
    {text: 'ID', value: 'id'},
    {text: 'Name', value: 'name'},
    {text: 'Type', value: 'type'},
    {text: 'Version', value: 'ocppVersion'},
  ];

  onEditItem(item: CentralSystem) {
    if (!item.readOnly) {
      this.$router.push({
        name: 'centralsystems-dialog-edit', params: {
          id: item.id.toString()
        }
      });
    }
  }

  onDeleteItem(item: CentralSystem) {
    this.$router.push({
      name: 'centralsystems-dialog-delete', params: {
        id: item.id.toString()
      }
    });
  }

  onRefresh() {
    this.$store.dispatch("centralSystems/fetchAll");
  }

  mounted() {
    this.$store.dispatch('specific/getConfig').then(
        value => this.$data.config = value
    );
    this.$store.dispatch("centralSystems/fetchAll");
  }
}

